<template>
    <div class="wisdom">
        <header class="wisdom-header">
            <h1>智慧医院评审咨询</h1>
        </header>
        <div class="wisdom-container">
            <div class="one wisdom-item" v-if="data.one != void 0">
                <module-title :title="data.one.title" />
                <div class="wisdom-item-content">
                    <div class="text">
                        <p class="main">{{ data.one.content }}</p>
                        <p class="sub-main">{{ data.one.subContent }}</p>
                    </div>
                    <img class="right-img" :src="data.one.img" alt="" />
                </div>
            </div>
            <div class="two wisdom-item" v-if="data.two != void 0">
                <module-title :title="data.two.title" />
                <div class="wisdom-item-content">
                    <div class="text">
                        <p class="main">{{ data.two.content }}</p>
                        <ul class="icons">
                            <li v-for="(item, i) in data.two.contentList" :key="i">
                                <img :src="item.img" alt="" />
                                <span>{{ item.title }}</span>
                            </li>
                        </ul>
                    </div>
                    <img class="right-img two-img" :src="data.two.img" alt="" />
                </div>
            </div>
            <div class="three wisdom-item" v-if="data.three != void 0">
                <module-title :title="data.three.title" />
                <div class="wisdom-item-content">
                    <ul class="list">
                        <li v-for="(item, i) in data.three.contentList" :key="i">
                            <img :src="item.img" alt="" />
                            <div class="list-text">{{ item.title }}</div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="four wisdom-item" v-if="data.four != void 0">
                <module-title :title="data.four.title" />
                <div class="wisdom-item-content">
                    <ul class="list-icons">
                        <li v-for="(item, i) in data.four.contentList" :key="i">
                            <img :src="item.icon" alt="" />
                            <p class="sub">{{ item.subContent }}</p>
                            <p class="main">{{ item.content }}</p>
                            <img :src="item.img" alt="" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // 智慧医院评审咨询
    // 组件
    import ModuleTitle from '@/components/FhiryModuleTitle';

    // vuex
    import { mapMutations } from 'vuex';

    // api
    import { getWisdom } from '@/api/request';

    export default {
        name: 'wisdom',
        created () {
            this.getWisdomAjax();
        },
        data () {
            return {
                data: ''
            }
        },
        methods: {
            ...mapMutations([
                'setBusinessBanners'
            ]),
            getWisdomAjax () {
                getWisdom().then(res => {
                    this.setBusinessBanners(res.banners);
                    this.data = res.data;
                })
            }
        },
        components: {
            ModuleTitle
        }
    }
</script>

<style lang="less">
    .wisdom {
        .wisdom-header {
            padding: 20px 40px;
            border-bottom: 1px solid #ccc;
            h1 {
                font-size: 22px;
                font-weight: bold;
            }
        }
        .wisdom-container {
            padding: 50px 40px;
            .wisdom-item {
                margin-bottom: 50px;
                &.one, &.two {
                    margin-bottom: 120px;
                } 
                &:last-child {
                    margin-bottom: 0;
                }
                .wisdom-item-content {
                    position: relative;
                    display: flex;
                    margin-top: 50px;
                    font-size: 14px;
                    .text {
                        line-height: 2;
                        width: 45%;
                        .sub-main {
                            color: #777;
                        }
                        .icons {
                            margin-top: 20px;
                            li {
                                display: flex;
                                align-items: center;
                                margin-bottom: 10px;
                                color: #e6ad56;
                                &:last-child {
                                    margin: 0;
                                }
                                span {
                                    margin-left: 14px;
                                }
                            }
                        }
                    }
                    .right-img {
                        position: absolute;
                        top: -100px;
                        right: 0;
                        &.two-img {
                            top: -80px;
                        }
                    }
                    .list {
                        display: flex;
                        justify-content: space-between;
                        li {
                            flex: 1;
                            margin-right: 20px;
                            text-align: center;
                            &:last-child {
                                margin-right: 0;
                            }
                            .list-text {
                                margin-top: 10px;
                                font-weight: bold;
                                line-height: 1.5;
                            }
                        }
                    }
                    .list-icons {
                        display: flex;
                        flex-wrap: wrap;
                        li {
                            margin-right: 20px;
                            margin-bottom: 20px;
                            width: calc(~'50% - 10px');
                            text-align: center;
                            &:nth-child(2n) {
                                margin-right: 0;
                            }
                            &:nth-last-child(-n + 2) {
                                margin-bottom: 0;
                            }
                            .sub {
                                margin-bottom: 6px;
                                margin-top: 6px;
                                color: #777;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .wisdom {
            .wisdom-header {
                padding: 0.26666rem 0.53333rem;
                h1 {
                    font-size: 0.48rem;
                }
            }
            
        }
    }

    @media screen and (max-width: 767px) {
        .wisdom {
            .wisdom-container {
                padding: 0.8rem 0.5333rem;
                .wisdom-item {
                    &.one, &.two {
                        margin-bottom: 1.3333rem;
                    } 
                    .wisdom-item-content {
                        flex-direction: column;
                        font-size: 0.32rem;
                        .text {
                            width: 100%;
                            .icons {
                                display: flex;
                                li {
                                    flex: 1;
                                    flex-direction: column;
                                    span {
                                        margin: 0.10666rem 0 0 0;
                                    }
                                }
                            }
                        }
                        .right-img {
                            position: static;
                        }
                    }
                }
            }
        }
    }
</style>